<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/banner.png" alt="" />
    </div>
    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/activity?id=1' }"
          >最新活动</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{
          title.substring(0, 6) + "..."
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->

    <div class="container">
      <!-- 无title -->
      <div class="box" v-if="wzid == '1785162'">
        <div class="p_box">
          <p>
            12月1日，全国税务系统新时代“枫桥式”税务所（分局、办税服务厅）建设交流推进会在京召开。会议深入学习贯彻习近平总书记关于坚持和发展新时代“枫桥经验”的重要指示精神，以新时代“枫桥式”税务所（分局、办税服务厅）建设的深入推进，推动基层税务部门坚持好发展好新时代“枫桥经验”，扎实履责力促税务系统第二批主题教育见实效，更好推进党中央关于更加注重强基固本的重要部署在税务系统不折不扣落实落地。税务总局党委委员、副局长姚来英出席会议并讲话，税务总局副局长赵静主持会议。中央主题办联络二组有关同志到会指导。
          </p>
          <p>
            会议指出，推进新时代“枫桥式”税务所（分局、办税服务厅）建设是税务总局党委认真学习贯彻落实习近平总书记关于主题教育重要讲话和重要指示批示精神，贯通推进税务系统第一、二批主题教育取得更大实效的重要实践。各地税务部门认真落实税务总局党委部署要求，扎实推进、稳步落实，基层治理责任进一步压实，基层治理能力进一步提升，基层治理活力进一步迸发，取得了良好成效。
          </p>
          <p>
            会议要求，各级税务部门要提升政治站位，进一步深化思想认识，
            充分认识到新时代“枫桥式”税务所（分局、办税服务厅）建设，
            是推动税务系统主题教育走深走实的有力抓手，
            是更好落实“践行宗旨为民造福”目标的必然要求，
            是夯实税收现代化建设基层基础的现实需要。
            要坚持党建引领，进一步提高推进效能，在强化责任落实、强化统筹协调、强化实绩实效中，
            促进基层税务部门更加稳妥有效地把矛盾问题解决在一线、化解在萌芽状态，
            更好推进办实事解民忧，让纳税人缴费人的获得感、幸福感、
            安全感更加充实、更有保障、更可持续。要坚持典型引路，进一步营造良好氛围，
            统筹用好经验交流、宣传推介、选树典型等工作方法，
            不断书写新时代“枫桥经验”税务实践新篇章，
            为夯实税收现代化服务中国式现代化根基作出新的更大贡献。
          </p>
          <p>
            会上，来自陕西、浙江、四川、河北、广东、青海基层税务部门的主要负责人围绕推进新时代“枫桥式”税务所（分局、办税服务厅）建设进行了交流发言。
          </p>
          <p>
            会议以视频形式召开。税务总局有关司局和主题教育办负责同志在主会场参加会议。各省、自治区、直辖市和计划单列市税务局有关负责同志在分会场参加会议。
          </p>
        </div>
      </div>
      <!-- <div class="box">
        <div class="p_box">
          <p>
            12月1日，全国税务系统新时代“枫桥式”税务所（分局、办税服务厅）建设交流推进会在京召开。会议深入学习贯彻习近平总书记关于坚持和发展新时代“枫桥经验”的重要指示精神，以新时代“枫桥式”税务所（分局、办税服务厅）建设的深入推进，推动基层税务部门坚持好发展好新时代“枫桥经验”，扎实履责力促税务系统第二批主题教育见实效，更好推进党中央关于更加注重强基固本的重要部署在税务系统不折不扣落实落地。税务总局党委委员、副局长姚来英出席会议并讲话，税务总局副局长赵静主持会议。中央主题办联络二组有关同志到会指导。
          </p>
          <p>
            会议指出，推进新时代“枫桥式”税务所（分局、办税服务厅）建设是税务总局党委认真学习贯彻落实习近平总书记关于主题教育重要讲话和重要指示批示精神，贯通推进税务系统第一、二批主题教育取得更大实效的重要实践。各地税务部门认真落实税务总局党委部署要求，扎实推进、稳步落实，基层治理责任进一步压实，基层治理能力进一步提升，基层治理活力进一步迸发，取得了良好成效。
          </p>
          <p>
            会议要求，各级税务部门要提升政治站位，进一步深化思想认识，
            充分认识到新时代“枫桥式”税务所（分局、办税服务厅）建设，
            是推动税务系统主题教育走深走实的有力抓手，
            是更好落实“践行宗旨为民造福”目标的必然要求，
            是夯实税收现代化建设基层基础的现实需要。
            要坚持党建引领，进一步提高推进效能，在强化责任落实、强化统筹协调、强化实绩实效中，
            促进基层税务部门更加稳妥有效地把矛盾问题解决在一线、化解在萌芽状态，
            更好推进办实事解民忧，让纳税人缴费人的获得感、幸福感、
            安全感更加充实、更有保障、更可持续。要坚持典型引路，进一步营造良好氛围，
            统筹用好经验交流、宣传推介、选树典型等工作方法，
            不断书写新时代“枫桥经验”税务实践新篇章，
            为夯实税收现代化服务中国式现代化根基作出新的更大贡献。
          </p>
          <p>
            会上，来自陕西、浙江、四川、河北、广东、青海基层税务部门的主要负责人围绕推进新时代“枫桥式”税务所（分局、办税服务厅）建设进行了交流发言。
          </p>
          <p>
            会议以视频形式召开。税务总局有关司局和主题教育办负责同志在主会场参加会议。各省、自治区、直辖市和计划单列市税务局有关负责同志在分会场参加会议。
          </p>
        </div>
      </div> -->
      <div class="box" v-if="wzid == '1785136'">
        <p>
          社保连着千家万户，事关民生福祉。第二批主题教育开展以来，各地税务部门对标对表习近平总书记提出的“三个实实在在”的重要指示精神，在税务总局党委统筹指导下，坚持以缴费人为中心，聚焦社保缴费中的痛点、难点、堵点问题，完善社保缴费渠道，改进社保缴费服务举措，切实为缴费人纾困解难，推动社保缴费服务更加便捷高效，将税务温度融入民生服务。
        </p>
        <p>
          <span class="title">立等可办 社保缴费“不上岸”“不出村”</span>
          <span>
            税务系统第二批主题教育单位在推动主题教育走深走实的过程中，大力开展调查研究，聚焦自然人社保缴费线上不会、线下较远的问题，多措并举将社保缴费服务延伸至基层末梢，让税费服务走进“村门口”，走上“海岸线”，助力实现应保尽保、老有所养、病有所医的目标。
          </span>
          <span>
            “之前去城里办理社保医保缴费业务，要先坐快艇渡船到码头，然后转客车，之后搭公交，来回一趟就要花一天时间，到了上鱼时节，根本抽不开身。现在方便多了，我就在海上社保医保服务站办理了，既方便又快捷。”福建省宁德市三都澳渔民张先生称赞税务部门便民措施说。
          </span>
          <span>
            张先生提到的海上社保医保服务站是福建省宁德市税务局第二批主题教育开展以来为民办实事的创新举措。宁德市税务局聚焦三都澳渔民“城乡两险”缴纳的困难情况开展调研时，发现三都澳21.6公里的海岸线上有着数以万计的海上从业者，“城乡两险”缴费耗时长、路途远。为满足广大渔民对社保医保业务“就近办”的迫切需求，宁德市税务局联合医保、人社等部门以及三都镇政府、中国移动三都海上营业厅，在三都澳海域挂牌成立海上社保医保服务站，实现社保医保参保缴费报销“不出岛”办理。
          </span>
          <span>
            四川省绵阳市税务局聚焦村镇老年人线上缴费不会用，习惯前往办税服务厅办理社保业务，但办税厅路途较远的问题，联合四川农信（绵阳）开发移动智能办费终端——税银POS机，将社保医保缴费服务延伸至社区（村）基层末梢，在221个乡镇（街道）便民服务中心实现税银POS机扩围投放，让税费服务“不出村”。
          </span>
          <span>
            “真是方便！到街上赶个场就顺便办了社保缴费，拿到缴费小票后，心里就踏实了。”四川省绵阳市平武县水晶镇的冯爷爷在家门口的便民服务中心，通过税银POS机刷卡成功缴纳2024年度城乡居民医疗保险费，再也不用前往30多公里外的县城办税服务厅缴纳医保了。
          </span>
        </p>
        <p>
          <span class="title">接诉即办 诉求响应“有速度”“显温度”</span>
          <span>
            主题教育成效如何，缴费人困难诉求的解决情况是重要标尺。税务系统第二批主题教育单位深刻领会把握习近平总书记关于践行宗旨为民造福的重要指示要求，坚决做到“民呼我为”，对缴费人关切的问题及时开题作答、回应诉求。
          </span>
          <span>
            广东省茂名市税务局在调研过程中，企业缴费人反映，目前仍有部分企业社会保险费业务需要线下办理，不少企业财务人员希望能够尽早实现全部业务网上办，以降低办理业务的时间成本。广东省茂名市税务局及时回应诉求，以优化社保缴费流程、提升缴费服务质效为切入口，聚焦“三张清单”试点推行“企业社保线上办全覆盖”，依托V-Tax远程可视化办税平台（以下简称V-Tax），实现42项企业社保业务“线上办全覆盖”，缴费单位“一次不用跑”。
          </span>
          <span>
            “没想到税务部门这么快就实现了全部企业社保业务‘线上办’！坐在办公室，使用V-Tax就可以轻轻松松完成社保业务事项办理，省心又省力。”自己的诉求被听见、被实现，茂名市喜闻文化传媒有限公司曾银波为茂名市税务局点赞。
          </span>
        </p>
        <p>
          <span class="title">联动共办 权益保障“一站式”“一体化”</span>
          <span>
            社保缴费事事皆民生，件件连民心，第二批主题教育开展以来，税务部门积极落实主题教育相关要求，聚焦“切实为缴费人办实事解难题”，以解难题的用心用情用力诠释主题教育成效，为缴费人的幸福生活撑起“保障伞”。
          </span>
          <span>
            “遇到医保费争议问题，原本以为要跑好几个部门，没想到在大厅，就能有税务、医保工作人员联合归口收集处理，感觉省心不少，也帮我解决了医保缴费纠纷的大问题，为税务部门点赞。”大连某公司离职人员田女士说道。
          </span>
          <span>
            田女士点赞的归口收集处理是大连瓦房店市税务局根据税务总局部署，在主题教育中积极创建新时代“枫桥式”税务所（分局、办税服务厅），联合医保部门共同打造“联调联动”医保费征缴争议联合处置模式，实现医保争议处置“只进一扇门＋争议一站结”，把缴费人的急难愁盼问题解决在基层。
          </span>
          <span>
            内蒙古自治区阿拉善左旗税务局通过进驻4个街道党群服务中心的“就近办”税费服务点，联合人社、医保、信访等多部门协作参与创建社保费征缴争议处理中心，把社保费征缴争议化解的“服务窗口”搬到群众“家门口”，对社会保险费征缴争议实行“一站式接手、联合式化解”，打通争议受理、调处、防范全链条，有效化解社保费征缴风险，推进社会保险费征缴争议化解在基层、化解于萌芽，避免群众“多头跑”，形成社保征缴的共治合力。
          </span>
          <span>
            “用人单位未缴纳社保，离职职工该怎么办？”内蒙古自治区阿拉善左旗孙女士最近从原单位离职时发现该公司长期欠缴社保，她到社保费征缴争议处理中心寻求帮助。处理中心受理诉求后，立即与企业负责人联系，向诉求人与涉事企业负责人详细讲解，并就双方诉求提供切实可行的解决方案。通过“面对面”沟通，孙女士顺利补缴了社保，切实维护了自身的合法权益。
          </span>
          <span>
            保障和改善民生没有终点。税务部门将以扎实开展主题教育为契机，持续深入基层、走进一线，倾听缴费人诉求，从缴费人的实际需求出发，确保社保缴费难题得到精准解决，不断提升缴费人获得感，以优化社保缴费服务的新成效检验税务系统主题教育成果。
          </span>
        </p>
      </div>
      <!-- 单title -->
      <div class="box" v-if="wzid == '1785113'">
        <p>
          今年以来，山西省税务部门充分发挥税收职能作用，紧盯发展大局、紧扣企业需求、紧贴基层实际，加力加码优化服务举措，精准精细释放政策红利，扎实推动政策落实提质增效、便民服务提档升级。
        </p>
        <p>
          <span class="title">税惠红利助推转型发展</span>
          <span>
            “税务部门‘三免三减半’的政策让新能源企业真正得到了实惠，让我们在转型发展的道路上走得更加坚定，更有信心！”盂县晋阳新能源发电有限公司财务主管员成栋竖起大拇指，“税务部门在政策上的贴心周到服务，为企业节本增效提供了便利条件。”
          </span>
          <span>
            自2016年山西省盂县获批建设国家生态光伏领跑者示范基地后，当地税务部门多措并举助力县域新能源产业高质量发展，目前中广核西潘风电、粤电西烟风电等31个新能源发电项目已初具规模。聚焦助力提升产业能级，国家税务总局盂县税务局成立了“新能源重点企业入企服务”领导组，发挥税务网格员的专业优势和地域优势，采取“税务所长包项目，税务干部驻项目”工作方式，为企业项目落地、投产、达效全流程提供税费政策靶向宣讲、税惠政策直达快享、涉税需求及时响应等精准服务。同时，加大“银税互动”合作力度，创建信用分享清单，促进企业多渠道低成本融资。除此之外，主动与县委、县政府汇报沟通，为全县落实“双碳”目标、深化能源革命综合改革、探索高碳地区低碳发展新路径增添了“税”动能。
          </span>
        </p>
        <p>
          <span class="title">精准施策响应民生关切</span>
          <span>
            冬季供暖涉及千家万户、关系群众冷暖，是民生工程更是民心工程。为助力供热企业完成能源保供任务，国家税务总局河津市税务局高效落实延续实施的供热企业有关税收政策，切实帮助供暖企业解决燃“煤”之急。
          </span>
          <span>
            据悉，河津市中环寰慧节能热力有限公司主要提供城市23万户居民供热服务。今年以来，由于煤炭价格大幅上涨、物流成本上升及该企业锅炉、管线、泵房、换热站等设备的检修更新，各项费用支出都比较高，企业资金一度紧张。针对供热企业面临的资金短缺问题，河津市税务局组织“税务管家”政策宣讲团开展“一对一”专项辅导行动，深入供热企业对所涉及的增值税、房产税、城镇土地使用税等优惠政策进行详细解读，推动税惠红利转化为供热企业持续发展的动能，用精细服务写好税务“民生”答卷。
          </span>
          <span>
            “税费优惠为企业的发展带来了实实在在的好处，降低了企业的税收负担，让我们有更多资金用在部分管线线路改造及老旧管线更新，进一步提高今冬的供热质量，让老百姓暖心过冬。”河津市中环寰慧节能热力有限公司法人谢梓茂说道。
          </span>
        </p>
        <p>
          <span class="title">优化服务提升办税体验</span>
          <span>
            “真没想到，电子税务局推送的一条政策提醒，竟让公司享受到了税惠减免。”沁水县鑫建工贸有限公司办税人王伟伟说。原来，国家税务总局沁水县税务局在数据比对分析中发现该公司符合享受加计抵减政策条件，便立即辅导纳税人填写了加计抵减声明，顺利享受到了政策红利。
          </span>
          <span>
            据悉，为不断提升服务质效，山西省沁水县税务部门组织办税服务厅、税源管理分局和业务部门工作人员组建服务团队，主动上门为企业提供“家访式”问需、“流程式”辅导、“问诊式”服务，让企业享受税费优惠“零时差”“零误差”。同时，充分发挥税收大数据作用，对企业进行全方位、要素化筛选画像，对符合条件的企业实行清单管理，通过电子税务局、征纳互动平台、税企交流群等渠道精准推送优惠政策，跟进开展政策辅导，让企业懂政策、会操作、快享受。
          </span>
          <span>
            国家税务总局山西省税务局党委委员、总经济师刘世伟表示，纳税人缴费人所盼就是税务人所向，全省税务部门将树牢“以纳税人缴费人为中心”的服务理念，以更多实招、硬招推动税费服务更有感、更入心，持续优化税收营商环境。
          </span>
        </p>
      </div>
      <div class="box" v-if="wzid == '1784903'">
        <p>
          今年以来，山西省税务部门充分发挥税收职能作用，紧盯发展大局、紧扣企业需求、紧贴基层实际，加力加码优化服务举措，精准精细释放政策红利，扎实推动政策落实提质增效、便民服务提档升级。
        </p>
      </div>
      <!-- 双title -->
      <div class="box" v-if="wzid == '1784864'">
        <p>
          今年以来，随着我国一系列税费优惠政策落地生根，有力激发了经营主体活力，提升了创新发展动能。国家税务总局最新统计数据显示，今年1-10月份，全国新增减税降费及退税缓费16607亿元，其中民营经济纳税人是税费优惠政策的主要受益对象，金额占比近75%，制造业及与之相关的批发零售业是享受优惠占比最大的行业，受益最为明显。
        </p>
        <p>
          为确保党中央、国务院决策部署落到实处，税务部门以深入开展主题教育为契机，坚决当好税费政策落实“主攻手”，充分发挥税收大数据优势，8月以来持续开展政策精准推送超过5亿次，从“政策找人”到“政策落地”全环节持续提升税务行政效能，助力各类经营主体轻装上阵，更好服务高质量发展。
        </p>
        <p>
          <span class="center_title"
            >超40万户企业提前享受研发费用加计扣除政策</span
          >
          <span class="center_title">有效增强企业创新发展动能</span>
          <span>
            企业是技术创新的主体，研发费用加计扣除政策是推进创新驱动发展的重要政策之一。企业研发投入越多、减税就越多，有利于引导企业逐步形成“政策引导—研发投入—效益提高—加大研发投入”的良性循环。
          </span>
          <span>
            近年来，我国不断优化研发费用加计扣除政策，在今年3月将符合条件的企业研发费用加计扣除比例由75%统一提高到100%，并作为制度性安排长期实施。同时，新增7月预缴申报期作为政策享受时点，引导企业更快更好加大研发投入。前三季度，全国共40.3万户企业提前享受研发费用加计扣除政策，有效增强了企业研发投入的底气。
          </span>
          <span>
            在今年的世界制造业大会集成电路与新型显示展区，一套8k高清裸眼3D设备吸引了大批参观者驻足。这正是京东方科技集团股份有限公司自主研发的110英寸裸眼3D显示终端，观众不需佩戴3D眼镜即可感受3D视觉效果。
          </span>
          <span>
            作为半导体显示领域领先企业，京东方坚定不移走自主创新道路，在8K显示等领域不断取得突破，核心竞争力与日俱增。“企业取得的进步离不开税务部门提供的全税费种、全产业链条式的优质服务。截至今年10月底，我们已享受研发费用加计扣除近16亿元，为企业加大创新研发提供了源源不断的‘活水’。”合肥京东方税务经理焦建表示。
          </span>
          <span>
            在山西，银圣科技有限公司坚持将每年五分之一的销售收入投入研发，全力攻克各个技术难关，创造了8项国家发明专利，6项实现科技成果转化，3项属于国内领先，实现了从一个生产初级氧化镁产品的小工厂到国家级“专精特新”“小巨人”企业的转变。
          </span>
          <span>
            “今年截至前三季度，仅研发费用加计扣除一项，我们就享受了500余万元的税收优惠，我们将把省下来的钱全部作为后续研发项目的科研经费，让产品更具市场竞争力。”山西银圣科技有限公司财务人员贾燕玲说。
          </span>
        </p>
        <p>
          <span class="center_title"
            >民营经济纳税人享受税费优惠金额占比近75%</span
          >
          <span class="center_title">持续提振民营企业发展信心</span>
          <span>
            民营经济是国民经济和社会发展的生力军，同时也是各项税费优惠政策的主要受益对象。今年1-10月份，全国民营经济纳税人新增减税降费及退税缓费12385.6亿元，金额占比近75%。
          </span>
          <span>
            为支持小微企业和个体工商户发展，我国2023年初明确继续对月销售10万元以下的增值税小规模纳税人免征增值税，对适用3%征收率的增值税小规模纳税人减按1%征收率征收增值税。2023年8月1日，按照国务院部署，将上述政策执行期限延长至2027年12月31日，进一步稳定了市场预期，提振了小微企业发展信心。
          </span>
          <span>
            好政策更需好落实。税务总局重点聚焦以民营企业为主体的广大中小微企业和个体工商户所盼，有针对性推出了28条促进民营企业发展壮大的便民办税举措，并指导各地税务部门推出配套服务举措，切实为民营企业纾难解困。
          </span>
          <span>
            重庆小面是个体工商户的代表之一，在沙坪坝杨梨路开了10多年的勤琼面馆，在当地很有名气，每天人流量超200人次。“政府一直都很关注小店经济，截至今年10月底，光税费我们就减免了约2万元，对我们来说这抵得上一个员工好几个月的工资了。”勤琼面店老板樊普勤表示，虽说面店不大，但也养活了一家人，保障了3个人就业。
          </span>
          <span>
            留抵退税政策对支持民营经济、激发市场活力具有重要作用。在广东，茂名市电白区税务局不断深化对重点行业、重点企业的“全生命周期”跟踪服务，定期梳理符合增值税留抵退税纳税人及出口退税纳税人的名单，通过实施“滴灌式”宣传、精准式辅导等多项举措，畅通企业减税降费“绿色通道”。
          </span>
          <span>
            “留抵退税政策给力，办理退税操作便捷，税务部门服务到位，大大减轻了我们公司的资金压力。”茂名市电白区广业环境治理有限公司负责人在收到486万元退税款到账的短信后表示。
          </span>
        </p>
        <p>
          <span class="center_title"
            >依托税收大数据开展政策精准推送超过5亿次</span
          >
          <span class="center_title">努力确保经营主体应知尽知愿享尽享</span>
          <span>
            近年来，税务部门把精准推送税费政策作为贯彻落实党中央、国务院重大决策部署的重要举措和推行“精细服务”的重要内容，建立了“总局统筹—省局主责—市局细化—县局补充—分局兜底”五级联动的工作运转机制，让“政策找人”真正落到实处。
          </span>
          <span>
            税务总局纳税服务司司长沈新国介绍，税务部门根据不同行业、地域、企业类型、人员身份等，依托税收大数据自动识别、匹配和推送相应的政策内容，综合采取事前告知、事中提醒、事后跟踪的方式，进行“线上＋线下”全流程递进式的推送，尽可能帮助每一个符合条件的纳税人缴费人都能够享受到政策红利。
          </span>
          <span>
            “打开电子税务局，就能看到税务部门推送的税费政策提醒，一键点击就能获取，不用自己再到处找，很方便。”西藏日喀则市强瓦机械租赁有限公司财务负责人旦增平措对税务部门推出的“政策找人”服务点赞。
          </span>
          <span>
            今年以来，西藏税务部门通过税收大数据后台筛选、部门间信息共享等措施，精准筛选符合优惠政策条件的企业，形成政策精准推送清册，实现从“人找政策”到“政策找人”，从“海量搜索”向“一键获取”的转变。
          </span>
          <span>
            在江苏，无锡市惠山区税务部门在充分依托税收大数据精准推送的基础上，通过“税收网格员”模式进行兜底服务，借助税企交流群、征纳互动平台等多渠道加强各类税费新政宣传和操作辅导。今年以来已累计开展政策精准推送96批次，覆盖约43万户次。
          </span>
          <span>
            “每当国家新政策出台，税收网格员就会第一时间推送并辅导我们操作，比如个体工商户应纳税所得额减免从100万元提高到200万元政策，上半年就帮助企业及时享受7.5万元的税收优惠！”惠山区海景壹号海鲜酒店财务负责人刘文玉介绍道。
          </span>
          <span>
            近日，济南市莱芜区税务局组织业务骨干，主动上门为高新技术企业解答涉税问题，并认真梳理辖区内高新技术企业信息和数据，量身定制专属服务手册，确保企业知政策、会申报，助力企业走好创新研发之路，擦亮高新企业“智造”名片。
          </span>
          <span>
            “我们公司现拥有自主知识产权授权专利277项，公司创新发展的背后离不开税务力量的加持，今年前三季度，公司享受研发费用加计扣除税收优惠2617万元，让我们在产品的研发创新投入上更加从容。”位于莱芜的山东朗进科技股份有限公司财务负责人说。
          </span>
          <span>
            国家税务总局有关负责人表示，税务部门将继续在开展主题教育中不断优化税费优惠政策精准推送流程，用更大力度、更实举措确保政策红利精准直达经营主体，充分释放创新激励政策效应，以政策落实的“加”力前行，帮助经营主体“减”负增效，推动高质量发展“乘”势而进。
          </span>
        </p>
      </div>
      <div class="box" v-if="wzid == '1785179'">
        <p>
          为服务好资源型地区产业转型，今年以来，国家税务总局晋中市税务局充分发挥税收服务地方经济、助力地方特色产业发展的作用，多措并举推进各项政策落地落实。
        </p>
        <p>
          <span class="title">税惠添力 “税动能”焕发产业新活力</span>
          <span>
            浸泡、打磨、点卤、压制、上色……一颗颗小小的黄豆经过生产线工人师傅们的精心加工制作成了餐桌上营养美味的豆腐干，在寿阳县健民实业有限责任公司生产车间，工人师傅们正在为新订单而忙碌。
          </span>
          <span>
            “前几年受疫情影响，企业生产发展面临挑战，国家税务总局寿阳县税务局的扶企助企好政策帮我们渡过了难关，企业今年营收增加了21%，为我们积极开拓新的市场打下了坚实的基础。”企业财务负责人白茹感激地说道。
          </span>
        </p>
        <p>
          <span class="title">精细服务 “税惠雨”激发企业新潜力</span>
          <span>
            作为山西省最大的民营造纸企业，山西强伟纸业有限公司的发展壮大离不开科技创新的驱动，“造纸业作为重资产行业，面临资金投入大、技术革新快、市场竞争激烈，公司的发展壮大，离不开税收政策的大力支持，近三年来，我们享受了研发费用加计扣除，在认定高新技术企业后，还享受到了高新技术企业设备器具加计扣除。”企业负责人贾伟告诉记者。
          </span>
          <span>
            为了让企业完整、准确的享受研发费用加计扣除，降低企业的涉税风险，晋中市税务部门组建了以青年业务骨干为主体的研发费用大企业风险应对服务团队，聚焦企业在政策落实中的难点、疑点，帮助企业全面归集整理日常生产经营过程中符合条件的研发支出，确保政策精准落地。
          </span>
          <span>
            税惠政策带来的资金红利也源源不断“反哺”到企业发展中，“80万吨的环保包装纸生产线全面投产后，将有力填补晋中市环保包装纸行业的空白，我们也将全力拓展海外市场，让中国制造走进千家万户”。公司负责人对未来的发展信心满满。
          </span>
        </p>
        <p>
          <span class="title">提质增效 “税东风”迸发行业新动力</span>
          <span>
            在经纬智能纺织机械有限公司全自动精梳机现场运行视频中，机器人及传输带在其间运输着生产材料，纺丝在设备间密集而高速地来往，工人驾驶着电动车轻松穿梭于纺织设备之间，自动退管、自动换卷、自动接头、智能化生产等多项核心技术填补了国产精梳机无人值守智能化生产的空白，为纺织产业由“制造”向“智造”转型升级迈出了关键一步。
          </span>
          <span>
            作为纺织行业的老字号品牌企业，经纬智能也面临纺机市场饱满、行业周期调整的影响，“这两年国家出台的减税降费政策对企业的帮助很大，借助减税降费的‘东风’，不断攻关新技术，极大改善了企业面临的困境”，公司财务负责人王传华对税务部门采取有力举措落实税惠政策连声称赞。
          </span>
          <span>
            特色产业是壮大区域经济，推进产城融合的重要载体，为持续推动产业高质量发展，晋中市税务部门通过上门问需、电话回访、税企座谈等方式，深入了解企业个性化需求，并实施“一户一策”定制服务，通过短信、公众号、电子税务局政策速递、征纳互动平台定户推送等途径，开展“一对一”辅导、“线上+线下”精细服务等方式，精准落实一系列减税降费优惠政策，助力企业不断绘就发展新蓝图。
          </span>
        </p>
      </div>
      <div class="box" v-if="wzid == '1785112'">
        <p>
          国家税务总局忻州市税务局坚持以纳税人缴费人为中心，着力提升纳税服务质效，创新纳税服务举措，用“暖心服务”助力企业“舒心发展”。
        </p>
        <p>
          <span class="title">上门问需解难题，当好为民“服务员”</span>
          <span>
            宁武县环城影像有限责任公司财务负责人张文华表示：“税惠政策主动‘上门’，税费服务更加贴心，税务部门工作团队帮助我们熟悉掌握最新的税费优惠政策和享受政策优惠，有效缓解了企业发展的资金压力。”
          </span>
          <span>
            宁武县税务局工作人员通过进厂房、看材料、观产品，仔细了解企业的生产经营情况，认真听取了企业负责人关于企业的发展介绍，倾听企业发展中遇到的困境，并就企业未来的发展进行了探讨，对企业在发展过程中遇到的具体问题，当场解答、“现场开方”，切切实实为企业排忧解难。通过举办税企座谈会、税费服务、纳税人学堂等活动，将便民办税的“春风”吹拂到纳税人缴费人的“心坎”。
          </span>
        </p>
        <p>
          <span class="title">税惠政策精准达，做好发展“助推器”</span>
          <span>
            “新增7月申报期可以提早兑现政策红利，让我企业拥有更充足的现金流，有效缓解资金压力，我们将继续加大研发投入！”山西焦煤山煤国际河曲露天煤业公司财务部长闫龙表示。
          </span>
          <span>
            在服务企业过程中，河曲县税务局积极运用税收大数据，精准识别享受研发费用加计扣除的纳税人，通过打造师资队伍，做精辅导培训，为能源企业送上“落实税费优惠政策”。据悉，该公司瞄准打造国家一级安全生产标准化管理体系矿井目标，在标准化建设、提升、达标中投入大量的研发资金，新的研发费用加计扣除政策将为企业切实地“减负松绑”，为企业增添创新发展的活力。
          </span>
        </p>
        <p>
          <span class="title">智慧办税轻松享，建好服务“高速路”</span>
          <span>
            “不用跑办税服务厅，在税务干部的远程帮助下点击手机就完成了税费申报更正。现在办税越来越方便了，让我们感受到了春风般的温暖！”谈起远程辅导办税的体验，山西大唐岢岚风电有限公司办税人张萌坦言“如沐春风”。
          </span>
          <span>
            连日来，岢岚县税务局为提高办税效率，让更多纳税人感受到智慧办税的快捷与便利，特意将税源管理、风险防控等基础事项前移，使纳税人缴费人的“依申请”事项，按不同场景、分类集成办理，精准分析和响应纳税人需求，提升线上服务和分析应用能力，实现线上办税业务覆盖面，构建“办问协同、全程互动”征纳互动服务运营模式，不断拓展“非接触式办税”业务范围，将精准、智能、高效、便捷的互动融入税费服务全过程。
          </span>
          <span>
            忻州市税务局党委书记、局长王波冰表示，下一步，我们将继续做好“暖心服务”，通过主动分析税收数据、主动调研走访企业等方式，创新推出更多办税缴费服务举措，提升税费服务质效，优化税收营商环境，持续提高纳税人缴费人的满意度和获得感。
          </span>
        </p>
      </div>
      <div class="box" v-if="wzid == '1784840'">
        <div class="p_box">
          <p>
            为确保税费优惠政策落地生效，国家税务总局长治市屯留区税务局聚焦企业发展需求，通过“政策找人”“精准推送”“一企一策”等工作机制，帮助经营主体及时享受优惠政策，积极助力企业轻装上阵。
          </p>
          <p>
            研发费用税前加计扣除政策是国家鼓励科技创新的重要政策抓手。山西长清生物科技有限公司董事长史晓军表示：“研发费用加计扣除优惠政策让我们企业的生产成本大大降低，盘活了企业资金链，减轻了经营负担，从而进一步加大研发投入，让我们企业‘减负松绑’的同时，增添了发展的底气和信心。”
          </p>
          <p>
            据了解，为深入落实研发费用加计扣除税收优惠政策，屯留区税务局充分运用信息化手段，依托电子税务局、征纳互动平台等多渠道、交互式推送模式，分批次精准锁定纳税人，实现“政策主动找人”，确保税费优惠政策精准直达各类经营主体。
          </p>
          <p>
            区局税务局干部郭煜表示：“我局对全区高新技术企业、科技型中小企业及其他享受研发费加计扣除企业进行摸底，一对一开展纳税辅导和精准推送税费支持政策。全区共有7户高新技术企业、2户科技型中小企业、11户其他类型企业享受了研发费加计扣除政策，我局将20户企业全部纳入精细化服务范围。”
          </p>
          <p>
            此外，该局精准聚焦企业创新发展需求，在广泛开展纳税人培训辅导的基础上，为纳税人缴费人分行业、分业务、分性质进行立体“画像”，制定精细化服务企业名单，建立了129户“一企一策”入企服务工作台账，量身定制服务举措，有力保障了政策红利精准惠及市场主体。
          </p>
          <p>
            山西潞安集团余吾煤业有限责任公司是“一企一策”税收精细服务对象，其财务负责人郝丽霞说道：“收到屯留区税务局为我们企业量身定制的‘一企一策’税收专属服务手册，内容全面详实，服务细致贴心，帮助我们用好用足税收政策，让我们更省心、更省力，为企业高质量发展助跑，真是既感激又感动啊！”
          </p>
          <p>
            “下一步，我们将持续做好系列税惠政策辅导落实工作，充分发挥税收职能作用，在精准推送政策、简化办理流程、税惠直达快享上持续下功夫，确保纳税人应享尽享税惠政策，充分释放税惠红利，护航广大经营主体发展壮大。”区局党委委员、副局长曹辉表示。
          </p>
        </div>
      </div>
      <div class="box" v-if="wzid == '1784747'">
        <p>
          国家税务总局娄烦县税务局坚持以助力地方特色产业发展为关键抓手，有效回应光伏发电、乡村旅游和农产品专业种植等产业经营者的涉税诉求，让税费优惠政策和便民办税举措转化为服务乡村振兴的强劲力量。
        </p>
        <p>
          <span class="title">税惠落地 增能扩容添动力</span>
          <span>
            该县立足“三类光照地区、二类光照资源”等先天自然条件优势，大力推广分布式户用光伏项目，让屋顶、庭院变成“发电站”，有效破解了到户产业帮扶措施不力的难题。据统计，先后共建成光伏扶贫电站70座，共计89.447兆瓦，每年发电量约1.1亿度。
          </span>
          <span>
            国家税务总局娄烦县税务局积极融入地方转型发展大局，成立“光伏项目小分队”，为企业提供精准服务。整合推出了“光伏产业退减税套餐”，将留抵退税政策、乡村振兴税费优惠政策指引等送至企业。
          </span>
          <span>
            国电中兴光伏科技有限公司是最早在娄烦县成立的光伏公司之一，该公司光伏项目资金投入大，运维成本高。通过税务部门的帮助和辅导，国电中兴及时享受了企业所得税“三免三减半”优惠政策，为公司购置光伏太阳能板增能扩容，为保障群众稳步增收添了一把力。
          </span>
        </p>
        <p>
          <span class="title">精准服务 惠农富农增信心</span>
          <span>
            近年来，娄烦县杜交曲镇下石家庄村成立了汾河湾乡村旅游开发有限公司，形成了集果蔬采摘、垂钓、餐饮、住宿、娱乐为一体的农家庄园，吸引了大批城市游客。该公司负责人曹乐缤介绍，“在税务人员的帮助和辅导下，公司及时享受到了税收优惠政策，有了国家政策的扶持，公司发展的底气更足了，惠农富农的信心也更强了。”
          </span>
          <span>
            为更好服务乡村旅游产业发展，该局组织业务骨干团队认真了解乡村旅游企业经营发展情况和涉税诉求，依托“政策辅导—响应迅速—跟踪问效”的服务模式定制个性化服务，为企业提供“一站式”政策咨询服务、“提前式”政策宣传辅导、“清单式”税费政策指引，用税惠服务的“精准滴灌”推动税惠政策落地落实，助推特色农业向着专业化、规模化、集群化、现代化阔步向前。
          </span>
        </p>
        <p>
          <span class="title">智慧税务 线上办税享便利</span>
          <span>
            娄烦县杜交曲镇罗家曲村是山西省省级乡村振兴示范创建村，也是天然谷物的绝佳产地。该村通过与娄烦县旺鑫种植专业合作社深化合作，打造了“晋谷芝”富锌小米，深受市场欢迎，带动100多户村民实现稳定增收。
          </span>
          <span>
            “非接触式办税，让我们足不出户就能快速申报缴税，大大节省了时间，提高了效率。税务部门线上服务也不间断，现在虽然见面少，但感觉我们的距离更近了。”娄烦县旺鑫种植专业合作社负责人介绍。
          </span>
          <span>
            为更好地服务乡村中小型企业，该局积极拓展征纳互动方式，简化办税流程，公布18大类155项“最多跑一次”“全程网上办”清单，进一步压缩办税时间，提升办税效率；扩展“非接触式”办税缴费业务范围，大力推广“税邮合作”和“征纳互动平台”；成立集中处理中心，推行办审一体化服务，实现“线上线下融合办税、现场远程协同解决”。
          </span>
          <span>
            “娄烦县税务局将聚焦地方产业发展布局，充分发挥职能优势，笃行以税惠农、以税助农、以税兴农，让‘税力量’耕耘出更多满载幸福的沃野。”该局党委书记、局长孙军芳说。
          </span>
        </p>
      </div>
      <div class="box" v-if="wzid == '1784602'">
        <p>
          国家税务总局偏关县税务局精准聚焦糜子经济，持续推出帮扶实招硬招，为糜农、糜企纾困解难，助力擦亮偏关糜米“金字招牌”。
        </p>
        <p>
          <span class="title">政策红利精准送达 润泽糜农心田</span>
          <span>
            “今年是一个丰收年，从这两天收割的情况看，收成还可以。”地生金谷子专业合作社负责人王飞一边整理粮袋一边说道：“合作社能够快速发展，离不开税收政策的支持。税务部门为我们梳理、讲解农产品种植、加工、售卖过程中可享受的各类税费优惠政策，我们可以用节省下来的税款购买农机具，这让大家有十足的信心把合作社做大做强！”
          </span>
          <span>
            据悉帮助粮企稳步发展，守护好百姓的“粮袋子”，是偏关县税务局开展税费服务暖心行动的重要一环，县局聚焦粮食产业发展需求，深化精细服务，组织业务骨干，开展“送税下乡”活动，通过向种植户和农业合作社宣传六税两费、农产品抵扣等政策以及重点回答种植合作社关心的“符合政策条件的农机具不用缴纳车辆购置税、自产自销农产品免征增值税、从事农林牧渔业项目减免企业所得税”等相关政策，让涉农税费优惠政策点滴尽落粮企口袋，润泽粮食产业健康发展。
          </span>
        </p>
        <p>
          <span class="title">管家服务全程护航 助力企业成长</span>
          <span>
            山西益生元生物科技有限公司是偏关县集粮食生产、食品销售的一家综合性企业，作为老牌食品生产企业，目前正面临着原料价格上涨、人工费提高、行业竞争压力大等困境。
          </span>
          <span>
            为助力企业“粮言谷香”品牌应对经营难题，偏关县税务局针对企业产销情况，提供“一对一”管家式服务，定期了解企业生产经营情况、涉税服务需求，为企业定制相关税费优惠政策清单，提供“一企一策”线上线下纳税申报辅导，划好重点，动态更新，切实打通企业涉税难点堵点，将税惠红包稳稳送到企业手上，减轻企业负担。
          </span>
          <span>
            公司负责人张千厚表示，今年公司聚焦本地特色优质糜米，以精选的偏关优质糜米和芝麻为材料，专门推出一款糜米月饼，一举成为爆款“网红月饼”，也打响了“粮言谷香”这一品牌，这都离不开一系列税收优惠政策的支持，有了税务局“一对一”管家式服务，我们可以及时了解相关税收优惠政策，更有信心把企业做好，让“粮言谷香”月饼在更广阔的舞台崭露头角。
          </span>
        </p>
        <p>
          <span class="title">农企互动纾困解难 拉长产业“链条”</span>
          <span>
            与往年糜子收割秸秆全部粉碎还田不同，晋电化工有限责任公司24兆瓦农林生物质热电联产项目可实现糜子秸秆的能源化利用。了解到该企业正在收购糜子秸秆做原料，由于原材料收购较少导致无法大规模地开工后，偏关县税务局第一时间依托税收信息平台，联系上了种粮大户，征得同意后，交换了双方信息，打通了市场链条，促成供需双方有效购销。既解决了困扰企业的发展难题，又帮助了种粮大户增收致富，成功达成了合作双赢。
          </span>
          <span>
            “不仅送上了优惠政策，还帮助解决原材料购进问题，解决了我们的燃眉之急。你们带来的不只是原材料，更给我们企业的发展注入了无穷的信心！”该公司负责人杨秀茹说道。
          </span>
          <span>
            偏关县税务局党委书记、局长闫晶表示，下一步偏关县税务局将持续优化税收服务，精准送政策，精细解难题，加大税收宣传和涉农税收优惠政策执行力度，把税惠春风送至田间地头，为乡村振兴添彩增色，孕育一个丰收的美好愿景和未来。
          </span>
        </p>
      </div>
      <div class="box" v-if="wzid == '1784500'">
        <p>
          近年来，国家税务总局运城市税务局充分发挥税收职能作用，精准聚焦果农果企需求，在“政策输血”和“服务活血”上持续发力，让素有“南果”之称的运城水果走出了一条价值高、链条长、产业兴的农业高质量发展之路。
        </p>
        <p>
          <span class="title">暖心服务助“梨”甜蜜出圈</span>
          <span>
            在第七届山西（运城）国际果品交易博览会上，来自平陆县的玉露香梨一经亮相就吸引力大家的眼球。“今年果子大丰收，全村仅玉露香梨的产值就超过了50万元，我们老百姓的日子越来越甜啦！这些都离不开咱们国家的好政策，也特别要感谢税务部门的关心和支持！”平陆县南王村党支部书记郝为民向前来采访的记者介绍道。
          </span>
          <span>
            近年来，为服务果业发展，平陆县税务局成立“税惠助农”服务团队，深入各乡镇果园开展“送政策、问需求、解难题”走访活动，特别是在今年的采摘收获之际，举办涉农税收政策专题讲座，及时对果农个人代开发票、专项政策咨询等关注度较高的问题进行解答指导。
          </span>
          <span>
            10月16日，万荣县生产的百余吨梨罐头成功搭上中欧班列，将运城的梨香溢出国门。在此过程中，万荣县税务局聚焦减负担、减环节、减流程、减时限，组建优化完善出口退税工作小组，全面完善岗责配置，建立退税流程全环节联络群，进一步提高流程衔接速度，缩短退税时间，实现了退税资金“跑起来”，让运城果香甜蜜出圈。
          </span>
        </p>
        <p>
          <span class="title">“税务蓝”添彩 “红果果”变“金果果”</span>
          <span>
            闻喜县郭家庄镇坚持良种化、标准化、规模化和生态化种植，打造山楂“绿色品牌”，充分利用“互联网+”等模式，拓展生产、加工、储藏、销售于一体的产业链，把一颗颗“红果果”变成了“金果果”。
          </span>
          <span>
            “多年来，我们的果园种植规模不断扩大，实现了从零售到批发的转变，同时也带来了一个头疼的问题，那就是开票。”张樊村果农张虎介绍道，“多亏税务部门全方位的服务，我如今已经能熟练地用自己的手机开票了，解决了向果商销售的后顾之忧。”
          </span>
          <span>
            为了让果农果企安心抢抓“山楂”采摘季，闻喜县税务局收集归纳果农果企咨询较多的涉税问题，形成“助农攻略”送到果农果企手中。同时，聚焦果业发展需求，主动服务，通过线上征纳互动平台帮办、线下跟踪辅导问效的形式，为其提供“一户一策”综合式服务，切实解决农户涉税问题，让果农知惠、懂惠、享惠。
          </span>
          <span>
            稷山县税务局为助力当地农产品，尤其是水果产品行业发展，组建“果先锋”税务行业顾问团队，将服务前置，深入果农、果企了解困难，解决难题，让一颗颗普通的果子变成“致富果”。
          </span>
        </p>
        <p>
          <span class="title">税务“搭把手” “串”出致富路</span>
          <span>
            位于永济市的卿头镇白坊村有着多年的葡萄种植历史。为支持村民发展葡萄，形成规模效应，村里成立了安顺水果种植专业合作社，实行“合作社+公司+基地+农户”的经营模式，一手牵果农，一手联市场，与农户结成了紧密型利益联合体，实现了合作社与农户双赢。
          </span>
          <span>
            “合作社成立之初，由于不懂企业管理和财务管理，在这方面吃了不少苦头，经营压力非常大。”合作社负责人邵晓超说。了解情况后，永济市税务局精准施策，主动问需，为合作社开展“一对一”“点对点”服务，辅导合作社享受农产品增值税税收优惠减免，极大缓解了合作社的经营压力。
          </span>
          <span>
            “在推动葡萄产业发展实现乡村振兴的道路上，离不开税务部门的一路支持，”白坊村村委会主任介绍说，“税务部门还帮助我们与山西农业大学建立了合作关系，发展早、中、晚熟品种葡萄，打好错峰上市牌，推动葡萄产业提档升级。”
          </span>
          <span>
            在服务像“南果”这样的特色农业产业的道路上，运城市税务部门将始终聚焦行业发展需求，以更便民的税收服务、更专业的政策辅导、更良好的营商环境，推动农产品高质量转型发展，以“税务蓝”绘好乡村振兴新图景。
          </span>
        </p>
      </div>
      <div class="box" v-if="wzid == '1784424'">
        <p>
          民营经济是经济的活力之源、腾飞之翼。国家税务总局吕梁市税务局聚焦民营企业全周期涉税诉求，精心打造“税周到”服务品牌，最大力度帮助民营企业减负增效赋能、强筋健骨提质。
        </p>
        <p>
          <span class="title">“精准＋定制”政策直接“送上门”</span>
          <span>
            “近几年，集团公司研发费支出占比较高，税费优惠政策为我们减轻了不少压力，去年公司申报享受了研发费用加计扣除让我们在技术创新上有了更足的底气。”山西鹏飞集团财务负责人冀旺旺说道，“今年国家又优化了预缴申报享受研发费用加计扣除政策，这意味着我们手里攥着更多流动资金，再次为企业创新减了负、松了绑！”
          </span>
          <span>
            山西鹏飞集团是以煤焦化为支撑的现代化民企，也是吕梁的氢能产业链龙头企业。得益于研发费用加计扣除等税费优惠政策的支持，该企业11个氢能研发课题的中试基地已获科技部门批复，目前已有6项氢能领域创新成果获国家专利。为全面激发企业科技创新活力，吕梁市税务局精准把握纳税人需求，细心指导企业正确归集研发费用，精准助力科技企业创新发展、跨越升级。
          </span>
          <span>
            将政策红利转化为发展实效，是税务部门服务民营经济的关键一招。为了推动国家出台的各项税费优惠政策直达民营企业，吕梁市税务局分类梳理各行业相关政策，大力推行政策“免申即享”机制，围绕纳税人“学政策＋明条件＋填报表＋留资料”四个环节，着力打造纳税人“懂政策、能判断、享优惠、备资料”的闭环式优惠政策享受机制，充分发挥优惠政策与优质服务的乘数效应。山西省氢能产业联盟理事长、孝义鹏飞集团董事局主席郑鹏说：“国家的税费优惠政策不仅增加了我们加大研发投入的底气，更让我们亲身感受到国家对民营企业的支持。今年税务部门又为我们氢能企业量身定制了专属服务，有这么好的营商环境，鹏飞集团将全力加快在建氢能项目的推进力度，为全省高质量发展和现代化建设‘加氢赋能’”。
          </span>
        </p>
        <p>
          <span class="title">“精心＋服务”持续注入“税动能”</span>
          <span>
            “您好，本月的申报截至日期还有两天就到了，我看见咱公司这个月还没有申报，是有什么问题吗？”与往常一样，方山县税务局的梁熠榕打通了吕梁全顺达煤业有限公司财务负责人的电话，主动询问纳税人情况。
          </span>
          <span>
            聚焦民营企业转型发展的涉税需求，吕梁市税务局积极拓展税费服务管理现代化应用场景，在全市22个重点产业链的龙头民营企业和专精特新“小巨人”民营企业设立服务站，提供精准一对一税收优惠政策指导和风险预警分析。当地税务部门还在重点企业办公区设置了自助领票机、在办税厅设置了民企税费服务“专席”，并邀请企业相关负责人对税务部门落实政策、税收执法、纳税服务、税风税纪等进行日常监督和实际体验，推动税费服务质效持续提升。
          </span>
          <span>
            聚焦民营企业的所需所盼，税务工作人员积极开展民营企业调查，将大家关注的税务事项、希望的纳税辅导方式、耗时最多的办税事项、办税过程中遇到的堵点痛点难点等内容设计成调查问卷，通过“问卷星”平台、上门走访、纳税人学堂等平台发放，目前已收回有效问卷四百余份并形成调查结果。在此基础上，坚持问题导向、对标优质服务，为企业量身定制服务项目，促进服务质量进一步提升。“他们不光通过电话问计问需，而且还开展上门服务，从政策解读、电子税务局操作辅导、风险分析等方面进行全面解惑，让我们及时享受到最新的税费红利，真正实现了纳税服务到‘家’。”吕梁全顺达煤业有限公司的财务负责人武军说。
          </span>
        </p>
        <p>
          <span class="title">“精细＋特色”打通“最后一公里</span>
          <span>
            “家门口就能办理税务登记，还能申领发票，‘纳税服务室’真是太方便了！”临县糯米香青塘粽子坊法人牛伟兰说。
          </span>
          <span>
            临县前青塘村是远近有名的“粽子之乡”，村里有100余家粽子产业链的民营企业。围绕粽子企业需求，当地税务部门加强“5分钟办税圈”建设，专门在纳税人集中的前青塘村委大院设置了一个纳税服务室。这个村口的“迷你办税厅”能够辐射周围5公里的纳税人，可以为众多粽子民营企业办理代开发票等90%以上的税费业务，还能帮助纳税人了解最新税费政策。
          </span>
          <span>
            为帮助民营企业享受更加便捷、高效、精准的纳税缴费服务，吕梁市税务局持续优化网络互动式服务，构建民营经济“专服”平台，线下联合工商联等大力开展“春雨润苗”入企服务专项行动，线上与民营企业远程智能互动，推出“办中能问、问中能办、办问协同”服务。在此基础上，市县两级税务部门以政策派送“零误差”、辅导咨询“零推诿”、纾困解难“零距离”为原则，持续优化“点对点、面对面、端对端”个性化精细服务，与市人社、医保、自然资源、住建、公安、市场监管等部门联合拓展税费联动业务，深化当地特色的“税银电”平台运用，大力推动“以税促信、以信换贷、以贷助企”，统筹解决重点民营企业资金难题。
          </span>
          <span>
            “民营经济是地方高质量发展的优势‘引擎’，各项税费优惠政策和便民办税举措就是企业发展不可或缺的‘动力’，税务部门将充分释放政策红利，不断优化税费服务，持续为民营经济发展赋能添力。”吕梁市税务局党委书记、局长王韶峰表示。
          </span>
        </p>
      </div>
      <div class="box" v-if="wzid == '1784209'">
        <p>
          国家税务总局晋中市税务局充分运用税收大数据，紧紧围绕主场主体实际需求，全面、准确推送税费优惠政策，确保税费优惠政策直达快享，支持企业健康发展。
        </p>
        <p>
          <span class="title">企业“标签化”，政策推送更精准</span>
          <span>
           “企业的发展离不开税务部门的保驾护航，线上的答疑解惑、线下的‘把脉问诊’，税务部门总是第一时间为我们送来可以享受的税收优惠政策。”介休市伯乐汽车销售服务有限公司魏云龙负责人说。
          </span>
          <span>
            晋中市税务部门以大数据为驱动力，根据纳税人基础信息、企业规模、生产经营内容、申报数据等信息将纳税人标签化，靶向定位税收优惠政策“适用名单”。除此之外，税务部门还畅通信息渠道，与人社和退役军人事务等部门强化数据共享，提高纳税人标签的多样化，对享受政策市场主体精准画像、实时更新。
          </span>
          <span>
            将国家陆续推出的政策措施逐一梳理，归集到类、细化到项，与企业标签进行匹配，将优惠政策指引、电子税务局操作手册等信息利用征纳互动平台、税企微信群、短信等方式精准推送给每一位纳税人，更好实现“政策找人”。真正做到了用税收大数据的“有数”，推动优惠政策落实的“有力”。
          </span>
        </p>
        <p>
          <span class="title">服务“精细化”，政策享受更精准</span>
          <span>
            “我们企业主要是研发制作石墨新材料的，研发过程中资金周转非常重要，我想问一下国家对我们有哪些优惠的税收政策呢？”“什么是制造业企业？我们企业经营活动与制造沾边，就能享受研发费用加计扣除政策吗？”近日，一场研发费用加计扣除的政策辅导会在介休市税务局举行，税务人员对政策进行了深入梳理与讲解，多位企业负责人围绕相关企业研发情况进行现场提问，税务人员积极解答咨询。
          </span>
          <span>
            晋中市税务局不仅针对特殊政策进行精细化服务，还针对重点企业，组织“青年服务先锋队”点对点问计问需，办税教学。主动靠前服务，在专业镇产业园区、链主企业园区设立便民服务站，做到“一个产业项目+一支专业服务团队”，提供全流程涉税服务。在落实落细税收优惠政策的同时，提升了纳税人的满意度和获得感。
          </span>
          <span>
            “税务部门为公司提供的定制化服务给予我们很大帮助，为我们上门讲解相关政策，辅导我们享受税收优惠。”山西省义棠煤业有限责任公司王加洪说“今年前三季度，在税务干部的帮助下，我们成功享受了税收优惠，公司发展获得了充足的资金支持。”
          </span>
        </p>
        <p>
          <span class="title">宣传“多样化”，政策推广更精准</span>
          <span>
           “新的优惠政策一出台，就有税务部门的提示信息发送给我们，并附带相关操作指导。在电子税务局申报也非常方便，都是系统自动填报，我们只需核对申报内容是否有误，方便多了也准确多了。”山西科尔环保科技股份有限公司负责人刘毅表示。
          </span>
          <span>
            今年以来，晋中市税务部门聚焦延续优化完善的系列税费优惠政策，多样化开展税收宣传工作。通过征纳互动平台、微信公众号、网络直播平台、电子税务局精准推广税收优惠政策，精准对接高新技术企业等市场主体，面对面解答纳税人、缴费人急难愁盼问题。
          </span>
          <span>
            晋中市税务局税务党委委员、副局长毛永胜表示，我们将持续优化精准推送工作，聚焦聚力实绩实效，在落实税费优惠政策中助推企业发展，在优化办税缴费服务中增进人民认同，在服务高质量发展中彰显税务担当。（王晓武）
          </span>
        </p>
      </div>
    </div>

    <!-- <div class="btn" @click="subInfo">立即体验</div> -->
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      wzid: 0,
      title: "",
    };
  },
  watch: {},
  computed: {},
  methods: {
    subInfo() {
      this.$router.push({
        path: "/info",
        query: { org: "cw" },
      });
    },
  },
  created() {
    this.title = sessionStorage.getItem("hdtitle");
    this.wzid = this.$route.query.id;
  },
  mounted() {},
};
</script>
<style scoped>
.wrapper {
}

.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.box {
  width: 1200px;
  padding: 30px 0;
  margin: 0 auto;
}

.img_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
}

.img_box span {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
}

.img_box img {
  display: block;
  width: 100%;
}

.simg_box {
  display: flex;
  justify-content: center;
}

.img_box1 {
  width: 50%;
}

.img_box1 img {
  width: 200px;
  height: 350px;
}

.box p {
  text-indent: 2em;
  font-size: 18px;
  color: #333333;
  line-height: 34px;
  padding: 20px 0;
  text-align: justify;
}

.box p:first-child {
  padding: 0;
}

/* .box p.p_box {
  font-size: 18px;
  color: #333333;
  line-height: 34px;
  padding: 20px 0;
  text-align: justify;
} */

.box p span.title {
  display: block;
  font-weight: bold;
  margin: 10px 0;
}

.box p span.center_title {
  width: 100%;
  text-align: center;
  display: block;
  font-weight: bold;
  margin: 10px 0;
}

.box p span {
  display: block;
  margin: 10px 0;
}

.btn {
  width: 130px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 25px;
  background: #055afe;
  color: #fff;
  margin: 20px auto;
  cursor: pointer;
}

table tr th {
  text-align: center;
  padding: 5px;
  font-size: 14px;
}

table tr td {
  text-align: center;
  padding: 10px;
}
</style>
